import * as React from "react";
import { PricingTier } from "./molecules/pricing-tier";

export const CaregiverPricing = ({
  onboardingId,
  onButtonClick = (
    event = { eventName: "", eventParams: {}, navigationRoute: "" }
  ) => undefined,
}) => {
  return (
    <div className="columns is-centered is-multiline">
      <div className="column px-0 py-0 is-12-mobile is-10-tablet is-8-desktop">
        <h5
          className="text is-size-6-mobile is-size-5-desktop pb-5"
          style={{
            lineHeight: "1.2",
          }}
        >
          At neumind, we know it takes dedication and teamwork to support our
          loved-ones. Support, encourage and discover how you can support their
          long-term independence and joy.
        </h5>
      </div>
      <PricingTier
        pricingTier={{
          name: "Early Bird",
          cost: "$4/month",
          fullPrice: "$10",
          priceCaption:
            "Join us as an early user today and receive a 60% discount!",
          items: [
            "Tools to support your loved-one’s cognition and daily-living. ",
            "Library of strategies, fun activities, and courses to boost their independence and support informal care",
            "Share stories, tips, and encouragement in our member community",
            "Get recommendations tailored to your loved one's specific needs and goals",
          ],
          extraInfo: "Add your loved-one for free!",
          buttons: [
            {
              label: "Start 2 Week Free Trial",
              id: "start-trial-basic-c",
              isPrimary: true,
              onClick: () => {
                onButtonClick({
                  eventName: "TrialNeumindBasic",
                  eventParams: {
                    stakeholder: "Caregiver",
                    trialLength: "2 weeks",
                    cost: "$4/month",
                    tier: "basic",
                    onboardingId,
                  },
                  navigationRoute: `/app-link?install`,
                });
              },
            },
          ],
          bottomText: "If you forget to cancel, we'll give you a full refund.",
          bottomBoldText: "No questions asked!",
        }}
      />
    </div>
  );
};
