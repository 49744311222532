import { Widget } from "@typeform/embed-react";
import { Link, navigate } from "gatsby";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { v4 } from "uuid";

import { LayoutBox } from "../../components/atoms/LayoutBox";
import Layout from "../../components/Layout";
import { SurvivorPricing } from "../../components/SurvivorPricing";
import { CaregiverPricing } from "../../components/CaregiverPricing";

const delay = (ms) => new Promise((res) => setTimeout(res, ms));

const stakeholderFormId = "J8XAvr8a";
const survivorPrePricingFormId = "Exxuu8oP";
const caregiverPrePricingFormId = "Ix4zuQC8";
const professionalPrePricingFormId = "T6U5Npba"; // <-- 3p, 4p is w5RrXd0d but shelved until we take payment
const otherPrePricingFormId = "B8CAJYSd"; // <-- 3o, 4o is IRux33HI, 4p is w5RrXd0d but shelved until we take payment

const OnboardingAndPricing = (props) => {
  const [typeformData, setTypeformData] = useState();
  const [stakeholderFormResponseId, setStakeholderFormResponseId] =
    useState(null);
  const [lastFormEvent, setLastFormEvent] = useState(null);

  const [getError, setGetError] = useState(null);

  const searchParams = useMemo(
    () => new URLSearchParams(props.location.search),
    [props.location.search]
  );
  const typeformResponseId = searchParams.get("responseId");
  const onboardingId = useMemo(
    () => searchParams.get("id") || v4(),
    [searchParams]
  );

  const stakeholderQuestionResponse = typeformData?.answers?.find(
    (el) => el.field.id === "TXzVM83S2LQd"
  ).choice.id;

  const isSurvivor = stakeholderQuestionResponse === "LVBErka17K6M";
  const isCaregiver = stakeholderQuestionResponse === "74vJHDs4qJme";

  useEffect(() => {
    if (
      !isSurvivor &&
      !isCaregiver &&
      stakeholderQuestionResponse !== undefined
    ) {
      navigate(`/onboarding/thank-you?id=${onboardingId}`);
    }
  }, [isSurvivor, isCaregiver, stakeholderQuestionResponse, onboardingId]);

  useEffect(() => {
    const fetchData = async (id) => {
      await delay(100); // typeform doesnt return data right away

      const result = await fetch(
        `/.netlify/functions/typeform-response-by-id?responseId=${id}`
      );

      if (result.status !== 200) {
        setGetError(result.statusText);
        return;
      }
      const data = await result.json();

      if (data.items.length === 0) {
        navigate("/onboarding");
        return;
      }
      setTypeformData(data.items[0]);
    };

    if (typeformResponseId && !typeformData) {
      fetchData(typeformResponseId);
    }
  }, [typeformResponseId, typeformData]);

  useEffect(() => {
    if (lastFormEvent) {
      const { responseId, formId } = lastFormEvent;

      if (formId === stakeholderFormId) {
        setStakeholderFormResponseId(responseId);
        return;
      }
      if (
        [
          survivorPrePricingFormId,
          caregiverPrePricingFormId,
          professionalPrePricingFormId,
          otherPrePricingFormId,
        ].includes(formId) &&
        stakeholderFormResponseId !== null
      ) {
        navigate(
          `/onboarding?responseId=${stakeholderFormResponseId}&id=${onboardingId}`
        );
      }
    }
  }, [lastFormEvent, stakeholderFormResponseId, onboardingId]);

  const onButtonClick = useCallback(
    async ({ eventName, eventParams, navigationRoute }) => {
      if (window && window.dataLayer) {
        window.dataLayer.push({ event: eventName, ...eventParams });
      } else {
        console.log("no window or window.dataLayer", {
          window,
          dataLayer: window ? window.dataLayer : undefined,
        });
      }
      navigate(navigationRoute);
    },
    []
  );

  const setLastEvent = useCallback((event) => {
    setLastFormEvent(event);
  }, []);

  return (
    <Layout title="Onboarding">
      {typeformResponseId !== null ? (
        getError ? (
          <LayoutBox>
            <div>We are very sorry, but something has gone wrong.</div>
            <Link
              to="/onboarding"
              className={`button is-rounded is-medium mt-4`}
            >
              Start Onboarding
            </Link>
          </LayoutBox>
        ) : (
          <LayoutBox>
            {isSurvivor && (
              <SurvivorPricing
                responseId={typeformResponseId}
                onButtonClick={onButtonClick}
                onboardingId={onboardingId}
              />
            )}
            {isCaregiver && (
              <CaregiverPricing
                onButtonClick={onButtonClick}
                onboardingId={onboardingId}
              />
            )}
            {!isSurvivor && !isCaregiver && !typeformData && (
              <progress className="progress is-large is-primary" max={100} />
            )}
          </LayoutBox>
        )
      ) : (
        <LayoutBox>
          <Widget
            id="J8XAvr8a"
            autoResize={true}
            inlineOnMobile={true}
            shareGaInstance="G-PXH6W84PN1"
            redirectTarget="_self"
            tracking={{
              utm_source: searchParams.get("utm_source"),
              utm_medium: searchParams.get("utm_medium"),
              utm_campaign: searchParams.get("utm_campaign"),
              utm_term: searchParams.get("utm_term"),
              utm_content: searchParams.get("utm_content"),
            }}
            onSubmit={setLastEvent}
            hidden={{
              utm_source: searchParams.get("utm_source"),
              utm_medium: searchParams.get("utm_medium"),
              utm_campaign: searchParams.get("utm_campaign"),
              utm_term: searchParams.get("utm_term"),
              utm_content: searchParams.get("utm_content"),
              onboarding_id: onboardingId,
            }}
          />
        </LayoutBox>
      )}
    </Layout>
  );
};

export default OnboardingAndPricing;
