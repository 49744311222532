import * as React from "react";
import PropTypes from "prop-types";
import "./index.css";

import Icon from "@mdi/react";
import { mdiCheckCircle } from "@mdi/js";

export const PricingTier = ({ pricingTier }) => {
  return (
    <div className="column is-12-mobile is-5-desktop is-7-tablet">
      <div className="card card-equal-height has-background-light-blue">
        <div className="card-content pb-0 mb-0">
          <h1 className="title is-3 has-text-m3-primary pb-0 mb-0">
            {pricingTier.name}
          </h1>
          <div className="columns is-mobile is-12 py-0 my-0 pl-1 pr-0">
            <div className="column py-1 my-1 is-narrow">
              <div className="subtitle is-size-4-desktop is-size-5-mobile fullprice has-text-grey">
                {pricingTier.fullPrice}
              </div>
            </div>
            <div className="column py-1 my-1 pl-0 is-narrow">
              <div className="subtitle is-size-4-desktop is-size-5-mobile has-text-secondary-purple has-text-weight-semibold">
                {pricingTier.cost}
              </div>
            </div>
          </div>
          <div className="subtitle pt-0 is-size-5-desktop is-size-6-mobile mb-0 pb-0 is-6">
            {pricingTier.priceCaption}
          </div>

          <div className="container pl-0.5 pr-0.5">
            <hr className="my-2.5" />
          </div>
        </div>
        <div className="card-content card-content-equal-height pt-1 pb-1 ">
          <div className="content">
            {pricingTier.items.map((item) => (
              <div key={item} className="columns is-mobile pb-1">
                <div className="column is-1 pb-0 pl-0">
                  <span className="icon is-medium has-text-m3-primary is-centered">
                    <Icon path={mdiCheckCircle} />
                  </span>
                </div>
                <div className="column pl-3 pb-0">
                  <span className="subtitle is-size-6-mobile ">{item}</span>
                </div>
              </div>
            ))}
            <span className="subtitle is-size-6-mobile has-text-weight-bold pr-1">
              Bonus:
            </span>
            <span className="subtitle is-size-6-mobile">
              {pricingTier.extraInfo}
            </span>
          </div>
        </div>
        <div className="card-content">
          {pricingTier.buttons.map(({ onClick, label, isPrimary, id }, idx) => (
            <button
              key={id}
              type="submit"
              className={`button mb-4 is-medium is-fullwidth is-responsive${
                isPrimary ? " is-m3-primary" : ""
              }`}
              onClick={onClick}
            >
              <span>
                <b>{label ? label : "Start 2 Week Free Trial"}</b>
              </span>
            </button>
          ))}
          <div className="pt-1">
            <span className="is-size-6">
              {pricingTier.bottomText ? pricingTier.bottomText : null}{" "}
              <b>
                {pricingTier.bottomBoldText ? pricingTier.bottomBoldText : null}
              </b>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

PricingTier.propTypes = {
  pricingTier: PropTypes.shape({
    name: PropTypes.string,
    cost: PropTypes.string,
    items: PropTypes.array,
    buttons: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        onClick: PropTypes.func,
        isPrimary: PropTypes.bool,
      })
    ),
    bottomText: PropTypes.string,
    bottomBoldText: PropTypes.string,
  }),
};
