import * as React from "react";
import { PricingTier } from "./molecules/pricing-tier";

export const SurvivorPricing = ({
  onboardingId,
  onButtonClick = (
    event = { eventName: "", eventParams: {}, navigationRoute: "" }
  ) => undefined,
}) => {
  return (
    <div className="columns is-centered is-multiline">
      <div className="column px-0 py-0 is-12-mobile is-10-tablet is-8-desktop">
        <h5
          className="text is-size-6-mobile is-size-5-desktop pb-5"
          style={{
            lineHeight: "1.2",
          }}
        >
          At neumind, we know how tough living with a neurological condition can
          be. We want to be there for you to help you every step of the way.
        </h5>
      </div>

      <PricingTier
        pricingTier={{
          name: "Early Bird",
          cost: "$4/month",
          fullPrice: "$10",
          priceCaption:
            "Join us as an early user today and receive a 60% discount!",
          items: [
            "Tools to support cognition and everyday activities",
            "Library of strategies, fun activities, and courses to boost independence",
            "Share stories, tips, and encouragement in our member community",
            "Get recommendations tailored to your specific needs and goals – sign up using the same email address on our app to streamline this process",
          ],
          extraInfo: "Add a family member for free!",
          buttons: [
            {
              id: "start-trial-basic-s",
              label: "Start 2 Week Free Trial",
              isPrimary: true,
              onClick: () => {
                onButtonClick({
                  eventName: "TrialNeumindBasic",
                  eventParams: {
                    stakeholder: "Survivor",
                    trialLength: "2 weeks",
                    cost: "$4/month",
                    tier: "basic",
                    onboardingId,
                  },
                  navigationRoute: `/app-link?install`,
                });
              },
            },
          ],
          bottomText: "If you forget to cancel, we'll give you a full refund.",
          bottomBoldText: "No questions asked!",
        }}
      />
    </div>
  );
};
